<template>
  <div id="app">
    <Nav />
    <div class="pageMain">
      <div class="p20">
        <div class="flex between v-end">
        <img v-if="$i18n.locale == 'zh'" src="@/assets/t21.png" width="216" />
        <img v-else src="@/assets/t21e.png" width="216" />
        <div class="text-1 cur" @click="routerPush()" v-if="$i18n.locale == 'zh'">
          <img
            src="@/assets/music-1.png"
            alt=""
            srcset=""
            class="img-1 inbl-m"
          />
          <span class="inbl-m ml-7">租借乐谱</span>
        </div>
        </div>
        <div class="worksList mt20">
          <div class="worksListTiles">2018~2019</div>
          <el-row :gutter="10" class="worksListMain">
            <el-col :span="8">
              <img src="@/assets/line.png" width="75" />
              <div class="worksListYears">2018<br>~<br>2019</div>
            </el-col>
            <el-col :span="16">
              <router-link :to="{ name: 'WorksView', params: { id: 1 } }" class="worksListPic"><img src="@/assets/p21.png" /></router-link>
            </el-col>
          </el-row>
        </div>

        <div class="worksList mt20">
          <div class="worksListTiles">2019~2020</div>
          <el-row :gutter="10" class="worksListMain">
            <el-col :span="8">
              <img src="@/assets/line.png" width="75" />
              <div class="worksListYears">2019<br>~<br>2020</div>
            </el-col>
            <el-col :span="16">
              <router-link :to="{ name: 'WorksView', params: { id: 2 } }" class="worksListPic"><img src="@/assets/p24.png" /></router-link>
            </el-col>
          </el-row>
        </div>

        <div class="worksList mt20">
          <div class="worksListTiles">2020~2021</div>
          <el-row :gutter="10" class="worksListMain">
            <el-col :span="8">
              <img src="@/assets/line.png" width="75" />
              <div class="worksListYears">2020<br>~<br>2021</div>
            </el-col>
            <el-col :span="16">
              <router-link :to="{ name: 'WorksView', params: { id: 3 } }" class="worksListPic"><img src="@/assets/p25.png" /></router-link>
            </el-col>
          </el-row>
        </div>
        
        
        <div class="worksList mt20">
          <div class="worksListTiles">2022~2024</div>
          <el-row :gutter="10" class="worksListMain">
            <el-col :span="8">
              <img src="@/assets/line.png" width="75" />
              <div class="worksListYears">2022<br>~<br>2024</div>
            </el-col>
            <el-col :span="16">
              <router-link :to="{ name: 'WorksView', params: { id: 4 } }" class="worksListPic"><img src="@/assets/p26.png" /></router-link>
            </el-col>
          </el-row>
        </div>
        
      </div> 

      
      
      <Footer />

    </div>
    
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
  
  
    }
  },
  computed: {
      
  },
  mounted() {
    
  },
  methods: {
    // 租借乐谱
    routerPush() {
        this.$router.push({ name: "loanSheetMusic" });
    }
  },
}
</script>

<style scoped>
.pageMain img{
  max-width: 100%;
}
.text-1 {
  width: 95px;
  height: 30px;
  border: 1px solid #bf783d;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #bf783d;
}
.img-1 {
  width: 15px;
  height: 15px;
}
</style>

